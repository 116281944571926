/**
 * File navigation.js.
 *
 */
( function( $ ) {


  $(window).on("load resize orientationchange", function(){
    $("a.nav-toggle, .nav-overlay, .nav-wrapper, .sub-menu, li.menu-item-has-children").removeClass("active");
    $("body").removeClass("modal-open");
    $("a.nav-toggle").text("menu");
  } );


  if($("ul.nav li.menu-item-has-children").length){
    // open sub-menu when parent nav-item hovered
    // $("ul.nav li.menu-item-has-children").on("hover",function(e){
    //   $(this).find("a").addClass("active");
    //   $(this).find("ul.sub-menu").addClass("active");
    // });
    //
    // $("ul.nav li.menu-item-has-children .sub-menu").on("mouseout",function(e){
    //   $(this).removeClass("active");
    //   $(this).prev("a").removeClass("active");
    // });
  }

  if($(".sub-nav-toggle").length){
    // open sub-menu when parent nav-item clicked
    $(".sub-nav-toggle").on("click", function(e){
      e.preventDefault();
      $(this).closest('a').toggleClass("active");
      $(this).closest('a').next("ul.sub-menu").toggleClass("active");
    });
  }

  if($("a.nav-toggle").length){
    $("a.nav-toggle").on("click", function(e){
      e.preventDefault();
      if($(this).hasClass("active")){
        $(this).text("menu");
        $("body").removeClass("modal-open");
      }else{
        $(this).text("close");
        $("body").addClass("modal-open");

      }
      // $(".nav-overlay").toggleClass("active");
      $(this).toggleClass("active");
      $(this).next(".nav-overlay").toggleClass("active");
      $(this).next(".nav-overlay").find(".nav-wrapper").toggleClass("active");
    });
  }



} )( jQuery );
